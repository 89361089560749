<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Cashless from '@/services/Cashless';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import _ from 'underscore';


export default {
  components: { Layout, PageHeader, VueBootstrapTypeahead },
  page: {
      title: "Cashless",
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
  },
  watch: {
    'filterInput.serial': _.debounce(function(cust) { this.getCardsAutocomplete(cust, 'serial') }, 500),
  },
  data() {
    return {
        error: null,
        tableData: [],
        cashlessData: [],
        title: "Cashless",
        items: [
          {
            text: "Cashless",
            href: "/cashless",
          },
          {
            text:"Cards Management",
            active: true,
          }

        ],
        filterInput: {
          series: '',
          serial: '',
          rfid: '',
          status: '',
        },
        cardStatus: [
          { text: 'All', value: '' },
          { text: 'New', value: 'New' },
          { text: 'Valid', value: 'Valid' },
          { text: 'Cancel', value: 'Cancel' },
        ],
        isBusy: false,
        showErrorMessage : false ,
        filterClicked: false ,
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "timestamp",
        showTable: false,
        sortDesc: true,
        fields: [
            {
              key: "serial",
              label: "Card Serial",
              sortable: true,
            },
            {
              key: "series",
              label: "Card Series",
            },
            {
                key: "first_name",
                label: "Name",
                sortable: true,
                visible: true
            },
            {
              key: "rfid",
              label: "RFID",
            },
            {
              key:"subscription_number" ,
              label:"Seasson Ticket" ,
            },
            {
              key: "timestamp",
              label: "Last Updated",
              sortable: true,
            },
            {
              key: "status",
              label: "Status",
            },
            "action",
        ],
        
    };
  },
  computed: {

    /*Check if input fields are populated before submit */
      isFormValid() {
          return (
              this.filterInput.serial.trim() !== '' ||
              this.filterInput.series.trim() !== '' ||
              this.filterInput.rfid.trim() !== ''
          );
      },

    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },

  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  async created() {

  },
  methods: {


      submitForm() {
          this.filterClicked = true
          if (!this.isFormValid) {
              // Display an error message or handle the case when any of the required fields is empty
              this.showErrorMessage = true;
              return;
          }
          this.getCards();
          this.showErrorMessage = false;
      },

      /**
     * Search the table data with search input
     */
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      toggleTableVisibility() {
          this.showTable = true; // Show the table
      },
    async getCardsAutocomplete(query, inputTypeName) {
      this.cashlessData = []

      if(query.trim().length > 0){
        if(inputTypeName == 'serial'){
          query = 'serial='+query
        }
        const response = await Cashless.getCards(query)
        const rawData = await response
        var suggestions = [];
        rawData.data.data.forEach(function (item) {
          if(item.serial && inputTypeName === 'serial'){
            suggestions.push({text:item.serial});
          }
        });

        this.cashlessData = suggestions.filter((value, index, self) => self.map(x => x.text).indexOf(value.text) == index)
      }
    },

      async getCards(){
          try {
            this.toggleBusy();
            var filters = this.getFilters()
            const response = await Cashless.getCards(filters)
            this.tableData = response.data.data
            this.toggleBusy();
            this.toggleTableVisibility(); // Show the table

          } catch (error) {
            this.toggleBusy();
            this.error = error.response.data.error ? error.response.data.error : "";
            this.tableData = []
          }
        },

        getFilters(){
          var filter_string = '';
          if(this.filterInput.series) {
            filter_string += (this.filterInput.series) ? '&series=' + this.filterInput.series : "";
          }
          if(this.filterInput.serial) {
            filter_string += (this.filterInput.serial) ? '&serial=' + this.filterInput.serial : "";
          }
          if(this.filterInput.rfid) {
            filter_string += (this.filterInput.rfid) ? '&rfid=' + this.filterInput.rfid : "";
          }
          if(this.filterInput.status) {
            filter_string += (this.filterInput.status) ? '&status=' + this.filterInput.status : "";
          }

          return filter_string = filter_string.substring(1);
        },

  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">

            <div class="row mt-4">
              <div class="col-md-12">
                <div class="custom-accordion">
                  <a
                      class="text-body fw-semibold pb-2 d-block"
                      data-toggle="collapse"
                      href="javascript: void(0);"
                      role="button"
                      aria-expanded="false"
                      v-b-toggle.categories-collapse
                  >
                    <i class="mdi mdi-chevron-up accor-down-icon text-primary me-1"></i>Filters
                  </a>
                  <b-collapse visible id="categories-collapse">
                    <div class="card p-2 border shadow-none">
                        <div class="row">
                          <div class="col-sm-12 col-md-3">

                            <b-form-group label="Card Serial ID" label-for="formrow-serial-input" class="mb-3">
                              <vue-bootstrap-typeahead id="formrow-serial-input" ref="serialAutocomplete"
                                                       v-model="filterInput.serial"
                                                       :data=cashlessData
                                                       :serializer="s => s.text"/>
                            </b-form-group>
                          </div>
                          <div class="col-sm-12 col-md-3">
                            <b-form-group label="Card Series" label-for="formrow-series-input" class="mb-3">
                              <b-form-input v-model.trim="filterInput.series" type="text" id="series" aria-describedby="series-feedback"></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-sm-12 col-md-3">
                            <b-form-group label="RFID" label-for="formrow-rfid-input" class="mb-3">
                              <b-form-input v-model.trim="filterInput.rfid" type="text" id="rfid" aria-describedby="rfid-feedback"></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-sm-12 col-md-3">
                            <label class="control-label form-label">Status</label>
                            <b-form-select class="form-control" aria-describedby="status-feedback" :options="cardStatus" v-model="filterInput.status"></b-form-select>
                          </div>
                        </div>
                      <!-- Error message (displayed when filterClicked is true and the form is invalid) -->
                      <div class="col-sm-12 col-md-12">
                        <div class="alert alert-danger" v-if="filterClicked && !isFormValid" style="width:60%">
                          Please fill in at least one of the input fields (Card Serial ID, Card Series, or RFID) before submitting.
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col d-inline-flex">
                          <button type="button" class="btn btn-success me-2 w-lg" @click="submitForm">Filter</button>
                          <button type="button" class="btn btn-primary w-lg" @click="resetFilters">Reset</button>
                        </div>
                      </div>
                    </div>
                  </b-collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body" v-if="showTable">

            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="tableData"
                :busy="isBusy"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                show-empty
                empty-text="No Data Found"
              >
                <template v-slot:cell(first_name)="data">
                  <router-link title="View Customer" :to="{ path: `/customers/profile/${data.item.id}`}">
                    {{ data.item.first_name ? (data.item.first_name + ' ' + data.item.last_name) : '' }}
                  </router-link>
                </template>
                <template v-slot:cell(action)="data">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <router-link :to="{ path: `/cashless/card-details/${data.item.serial}`}" title="View Card Data">
                        <i class="uil uil-eye font-size-18"></i>
                      </router-link>
                    </li>
                  </ul>
                </template>

                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
            
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </Layout>
</template>
